/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

import Navbar from "../navbar/navbar"
import Styles from "./layout.module.css"

const Layout = ({ children }) => {
  return (
    <div className={Styles.Section}>
      <div className={Styles.Container}>
        <Navbar></Navbar>
        <main>{children}</main>
      </div>
    </div>
  );
}

export default Layout
