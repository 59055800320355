import React from "react"
import { ThemeToggler } from "gatsby-plugin-dark-mode"
import Styles from "./navbar.module.css"

const Navbar = () => {
  return (
    <div className={Styles.NavbarWrapper}>
        <h2>
        Hi, I'm Abhi
        <span role="img" aria-label="Emoji"> 👋</span>
        </h2>
        <div className={Styles.LinkWrapper}>
        <ThemeToggler> 
            {({ theme, toggleTheme }) => (
            <div className={Styles.DarkBtn}>
                <input 
                type="checkbox"
                id="toggle"
                onChange={e => 
                    toggleTheme(e.target.checked ? "dark" : "light")
                }
                checked={theme === "dark"}
                />
                <label htmlFor="toggle"></label>
            </div>
            )}
        </ThemeToggler>
        </div>
    </div>
  );
}

export default Navbar;